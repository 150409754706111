import React from "react";
import Layout from "../components/layout";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
const ResumePage = () => {
  return (
    <>
      <Layout>
        <article>
        <h1><Trans i18nKey="ResumePage.H1_Resume" /></h1>
        <h2><Trans i18nKey="ResumePage.H2_TheEarlyYears" /></h2>
        <Trans i18nKey="ResumePage.P_TheEarlyYears"></Trans>
        <h2><Trans i18nKey="ResumePage.H2_MyFirstJob" ></Trans></h2>
        <Trans i18nKey="ResumePage.P_MyFirstJob"></Trans>
        <h2><Trans i18nKey="ResumePage.H2_CimentsDObourg" /></h2>
        <Trans i18nKey="ResumePage.P_CimentsDObourg"></Trans>
        <h2><Trans i18nKey="ResumePage.H2_Holderbank" /></h2>
        <Trans i18nKey="ResumePage.P_Holderbank"></Trans>
        <h2><Trans i18nKey="ResumePage.H2_Clariant" /></h2>
        <h3><Trans i18nKey="ResumePage.H3_AsFinancialSystemAdmin" /></h3>
        <Trans i18nKey="ResumePage.P_AsFinancialSystemAdmin"></Trans>
        <h3><Trans i18nKey="ResumePage.H3_AsGPE" /></h3>
        <Trans i18nKey="ResumePage.P_AsGPE"></Trans>
        <h2><Trans i18nKey="ResumePage.H2_Today" /></h2>
        <Trans i18nKey="ResumePage.P_Today"></Trans>
        </article>
      </Layout>
    </>
  );
};

export default ResumePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
